import React from 'react'
import { async } from "@firebase/util";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import {Link} from "react-router-dom"
import {AiOutlineLoading3Quarters} from "react-icons/ai"
const ApplyJobs = () => {
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false)
    const [searchText,setSearchText] = useState("")
    
    const {user} = UserAuth()
    const getdata =  () => {
      setLoading(true)
      getDocs(collection(db, "applyjobs"))
      .then((res) => {
        setData(res.docs.map((i) => { return i.data()}))
    setLoading(false)
        
      })
      
      
      
  
      
    };
    useEffect(() => {getdata()},[])
  
    return <div className="workContain">
        <div className='WorkContainform'>
        <h3 id="w">Apply and start working</h3>
        <input onChange={(e) => setSearchText(e.target.value)} placeholder="Search a Job" />

        </div>
        <div className="work">

        {loading ? <>
            <div class="loader">
    <svg viewBox="0 0 80 80">
        <circle id="test" cx="40" cy="40" r="32"></circle>
    </svg>
</div>

<div class="loader triangle">
    <svg viewBox="0 0 86 80">
        <polygon points="43 8 79 72 7 72"></polygon>
    </svg>
</div>

<div class="loader">
    <svg viewBox="0 0 80 80">
        <rect x="8" y="8" width="64" height="64"></rect>
    </svg>
</div>
          </> 
          : null}
        {
        
        data.filter((val) => {
            if (searchText === "") {
                return val
            } else if (
             val.jobtitle.toLowerCase().includes(searchText.toLowerCase())
            ){
                return val
            }
          }).map((i)=> {
            
              return<div className="na">
              <h1>Job title: {i.jobtitle}</h1>
              <h1>Job type: {i.jobtype}</h1>
              
              <h1>Work Location: {i.worklocation}</h1>
              
              <h1>Monthly payment: {i.price}</h1>
              
              <Link to={`/applyjobpage/${i.id}`}>
              <button className="button">Apply</button>
    
              </Link>
              
                </div>
              
            
              
            
             
          })
          
          
          }

        
      </div>
        
  </div>
}

export default ApplyJobs
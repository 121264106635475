import {Routes, Route } from "react-router-dom";
import Findjob from "./components/Findjob";
import Home from "./components/Home";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Account from "./components/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import Chat from "./components/Chat";
import ApplyJobs from "./components/ApplyJobs";
import ApplyJobPage from "./components/ApplyJobPage";
import Message from "./components/Message";
function App() {
  

  
  return <>
    <Navbar />
  <Routes>
    
    <Route path="/" element={<Home />} />
  
    <Route path="/findjob" element={
    <ProtectedRoute >
 <Findjob />

    </ProtectedRoute>
   
    } />
    <Route path="/signin" element={<Signin />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/about" element={<About />} />
    <Route path="/account" element={<Account />} />
    <Route path="/message" element={<Message />} />
   
   
    <Route path="/chat" element={<Chat />} >
      <Route path="/chat:id"/>
    </Route>
    <Route path="/applyjobs" element={<ProtectedRoute><ApplyJobs /></ProtectedRoute>} />

    
    <Route path="/applyjobpage" element={<ApplyJobPage /> }>
<Route path="/applyjobpage:id" />
    </Route>
   
    
    
  
        
  
  
        
  
    
    </Routes>
  
    </>
 
    
      
 
}

export default App;

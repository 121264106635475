
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase"





const Findjob = () => {

 





    const [worklocation,setWorklocation] = useState("");
  const [jobtype,setJobtype] = useState("");
  const [jobtitle,setJobtitle] = useState("");
  const [description,setDescripion] = useState("")
const [price,setPrice] = useState("")
const [number,setNumber] = useState("")
const [chapaUrl,setChapaUrl] = useState("")
const [ref,setRef] = useState("")

  const navigate = useNavigate()
    
  const {user} = UserAuth()
 const {uid} = auth.currentUser
  
  const addData = async (e) => {
    e.preventDefault();
    if (jobtitle  === "") {
      alert("you forgeted a value");
    } else {

      
      await setDoc(doc(db, "applyjobs", uid), {
       jobtitle:jobtitle.toLowerCase(),
      
       price,
       jobtype:jobtype,
       worklocation:worklocation,
       description:description,
       email:user.email,
       id:uid

       
      })
      
      
      ;
    }
    
    navigate("/")

  };
  return <div className="post">
    <h3>Post job to hire</h3>
  <form className="postform" >
    <h3>Provide your Info(please be professional)</h3>
    
    <input
      value={jobtitle}
      onChange={(e) => setJobtitle(e.target.value)}
      placeholder="Job title"
    ></input>


    <input
      value={jobtype}
      onChange={(e) => setJobtype(e.target.value)}
      placeholder="Job type"
    ></input>
    <input
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      placeholder="Monthly payment"
    ></input>
   

    <textarea
    className="des"
      value={description}
      onChange={(e) => setDescripion(e.target.value)}
      placeholder="Description"
    ></textarea>
    <input
      value={worklocation}
      onChange={(e) => setWorklocation(e.target.value)}
      placeholder="work location"
    ></input>
    <button className="btn" onClick={addData}>submit</button>
  </form>
  </div>
}

export default Findjob
import React from 'react'

const Account = () => {
  return (
    <div>
        <h1>In production</h1>
        
        Account page</div>
  )
}

export default Account
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useSound from "use-sound";
import mySound from "./tate.mp3";
import { db } from "../firebase";
import s from "./s.png";
import {BsTelegram} from "react-icons/bs"
import { collection, getDocs } from "firebase/firestore";
const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  setTimeout(() => {
    const observer = new IntersectionObserver((e) => {
      e.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });
    const hiddenEle = document.querySelectorAll(".hidden");
    hiddenEle.forEach((el) => observer.observe(el));
  }, 100);
  const getdata = () => {
    setLoading(true);
    getDocs(collection(db, "applyjobs")).then((res) => {
      console.log(res.docs);

      setData(
        res.docs.slice(0, 6).map((i) => {
          return i.data();
        })
      );
      setLoading(false);
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="homeC ">
      <section id="main" className="hidden">
        <div className="row">
          <div className="home">
            <div className="hometext">
              <h1>
                Hello and welcome to{" "}
                <span className="gradient">Ethio freelancing</span>
              </h1>
              <h1>
                <span className="gradient">& job posts</span>
              </h1>
              <h4>Tired of searching for jobs in the sun.</h4>
              <h4>
                Get started sign up and start working your dream job.You can
                apply from where{" "}
              </h4>
              <h4>ever you want and start making a lot of money.</h4>
            
            <div className="c gradient">

            <h4 >Need a website for your business contact us -> </h4>
           <a href="https://t.me/nbndevs" target="_blank">
           <BsTelegram  size={30} color="#0088cc"/>


           </a>

            </div>
            </div>
          </div>
          <Link className="bb" to="/applyjobs">
            <button class="cssbuttons-io-button">
              {" "}
              Get started
              <div class="icon">
                <svg
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button>
          </Link>
        </div>
      </section>

      <section className="hn">
        <div className="workContain">
          <div className="WorkContainform">
            <h3 id="wh">Got to apply job page for more job posts</h3>
          </div>
          <div className="work">
            {loading ? (
              <>
                <div class="loader">
                  <svg viewBox="0 0 80 80">
                    <circle id="test" cx="40" cy="40" r="32"></circle>
                  </svg>
                </div>

                <div class="loader triangle">
                  <svg viewBox="0 0 86 80">
                    <polygon points="43 8 79 72 7 72"></polygon>
                  </svg>
                </div>

                <div class="loader">
                  <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                  </svg>
                </div>
              </>
            ) : null}
            {data.map((i) => {
              return (
                <div className="na nahome">
                  <h1>Job title: {i.jobtitle}</h1>
                  <h1>Job type: {i.jobtype}</h1>

                  <h1>Work Location: {i.worklocation}</h1>

                  <h1>Monthly payment: {i.price}</h1>

                  <Link to={`/applyjobpage/${i.id}`}>
                    <button className="button">Apply</button>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <footer>
        <div className="footer">
          <ul>
            <li>
              <a href="">About us</a>
            </li>

            <li>
              <a href="https://t.me/NATi_marvel" target="_blank">
                Contact
              </a>
            </li>

            <li>
              <a href="">Promotion</a>
            </li>
          </ul>
          <div>
            <h3>© NBN copyright reserved</h3>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;

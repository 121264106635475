import { async } from "@firebase/util";
import {
  collection,
  getDoc,
  doc,
  setDoc,
  getDocFromCache,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const ApplyJobPage = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = UserAuth();
  const getdata = async () => {
    setLoading(true);

    const docRef = doc(db, "applyjobs", `${params.id}`);
    const docSnap = await getDoc(docRef);
    setData([docSnap.data()]);
    setLoading(false);
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="workContain">
      <h3 id="w">Job description</h3>
      <div className="workk">
        {loading ? (
          <>
            <div class="loader">
              <svg viewBox="0 0 80 80">
                <circle id="test" cx="40" cy="40" r="32"></circle>
              </svg>
            </div>

            <div class="loader triangle">
              <svg viewBox="0 0 86 80">
                <polygon points="43 8 79 72 7 72"></polygon>
              </svg>
            </div>

            <div class="loader">
              <svg viewBox="0 0 80 80">
                <rect x="8" y="8" width="64" height="64"></rect>
              </svg>
            </div>
          </>
        ) : null}
        {data.map((i) => {
          return (
            <div className="jobD">
              <div>
                <h2>Job title: {i.jobtitle}</h2>
                <h2>job type: {i.jobtype}</h2>
                <h2>Work location: {i.worklocation}</h2>
              </div>

              <div>
                <h1>Description</h1>
                <div>{i.description}</div>
              </div>
              <Link to={`/chat/${i.email}`}>
                <button className="btn">Send Proposal</button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApplyJobPage;

import React,{useState,useEffect} from 'react'
import { UserAuth } from '../context/AuthContext'
import { doc, setDoc ,getDocs,addDoc,collection,serverTimestamp,query,onSnapshot,orderBy} from "firebase/firestore"; 
import { db,auth } from "../firebase";
import { useParams } from 'react-router-dom';
import {AiFillFileText} from "react-icons/ai"
const Message = () => {
  const {user} = UserAuth()
  const [n,setN]  = useState([])
  const [loading,setLoading]  = useState(false)
  

  useEffect(() => {
    setLoading(true)
    let id = user?.email
    
    const usersRef = collection(db, id);
    // create query object
    const q = query(usersRef);
    // execute query
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      setN(users);
      setLoading(false)
    });
    return () => unsub();
    

      
  }, [])
 
  return (
    <div className="workContain">
        <div className="work">

        {loading ? <>
            <div class="loader">
    <svg viewBox="0 0 80 80">
        <circle id="test" cx="40" cy="40" r="32"></circle>
    </svg>
</div>

<div class="loader triangle">
    <svg viewBox="0 0 86 80">
        <polygon points="43 8 79 72 7 72"></polygon>
    </svg>
</div>

<div class="loader">
    <svg viewBox="0 0 80 80">
        <rect x="8" y="8" width="64" height="64"></rect>
    </svg>
</div>
          </> 
          : null}
        {
        
        n.map((i)=> {
            
              return<div className="na">
              <h1>Full name: {i.name}</h1>
              <h1>GPA: {i.Gpa}</h1>
              <h1>Monthly payment: {i.price}</h1>
              <h1>Phone number: {i.num}</h1>
              
              <div className='nnn'>
                <div>
                <h2 >resume</h2>
                <a href={`${i.resume}`}>
                
                <AiFillFileText size={35} color="white"/>
              </a>

             

                </div>
              
              

              
             <a href={`https://t.me/${i.telegram}`}><button className='hireBtn' >Hire and Chat</button></a> 
              </div>
               
              
                </div>
              
            
              
            
             
          })
          
          
          }

        
      </div>
        
  </div>
    
    
  )
}

export default Message
import React, {createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  setDoc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { async } from "@firebase/util";
import { db, auth,storage } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {AiFillFileText} from "react-icons/ai"

const Chat = () => {
  const params = useParams();
  const navigate = useNavigate()
  const [telegram, setTelegram] = useState("");
  const [resume, setResume] = useState("");
  const [name,setName] = useState("")
  const [Gpa,setGpa] = useState("")
  const [num,setNum] = useState("")
  const [price,setPrice] = useState("")


 const user1 = auth.currentUser.email
 const user2 = params.id
 

 

  const SendMessage = async (e) => {
    
    
    e.preventDefault();
    let url;
    if (resume) {
      const rRef = ref(
        storage,
        `resume/${new Date().getTime()} - ${resume.name}`
      );
      const snap = await uploadBytes(rRef, resume);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
    }
    if(name === "" || telegram === "" || num === ""){
      alert("You forgot to enter a value")
      return;
    }
    await setDoc(doc(db, user2,user1), {
     
        name,
        email:user1,
price:price,
num,
        Gpa,
        telegram,
        timestamp: serverTimestamp(),
        resume: url || ""
      
    
     
   
    });
    navigate("/")
    alert("Proposal   have been successfully sent you will get hired if customer is satisfied. ")
    
    
  };

 

  return (<>
  
  <div className="applyForm">
      <label htmlFor="">Full name</label>
      <input type="text" value={name} onChange={(e)=> setName(e.target.value)}/>
      
      <label htmlFor="">Phone number</label>
      <input type="text" value={num} onChange={(e)=> setNum(e.target.value)}/>
      <label htmlFor="">Monthly payment</label>
      <input type="text" value={price} onChange={(e)=> setPrice(e.target.value)}/>
      <label htmlFor="">GPA  (skip if you dont have one)</label>
      <input type="text" value={Gpa} onChange={(e)=> setGpa(e.target.value)}/>

<label >Telegram account  (dont include @)</label>
<input type="text" value={telegram} onChange={(e)=> setTelegram(e.target.value)}/>

      <label htmlFor="file"><AiFillFileText size={35}/></label>
      <input type="file" 
      id="file"
        onChange={(e) => setResume(e.target.files[0])}
      
      ></input>


      <button onClick={SendMessage}>Submit</button>

      
  </div>
  
  </>
   
  );
};

export default Chat;

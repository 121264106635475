import React, { useState } from 'react'
import {Link} from "react-router-dom"
import { UserAuth } from '../context/AuthContext'
import {FaEnvelope} from "react-icons/fa"
import {AiOutlineMenu,AiOutlineClose} from "react-icons/ai"
import navl from "./navl.png"
const Navbar = () => {
    const {user,logOut} = UserAuth()
  const [nav,setNav] = useState(false)
  const handleNav = () => {
    setNav(!nav);     
  };
  return <div className='row r'>

  <nav className='nav '>
  <div >
      <Link className='bb ' to="/">
  
<img className="navLogo" src={navl} alt="" />      
      
      
      </Link>
  </div>
  <div className='x'>
  <div onClick={handleNav} >
        {nav ? <AiOutlineClose className='namma' size={25} color="violet" /> : <AiOutlineMenu className='namma' size={25} color="violet" />}
      
      
      </div>
  </div>

  
    <div className={nav ? "nd" : "ndd"}>
    
  <ul>
    {user?.email ? (
        <>
        <Link to="/message" onClick={handleNav}>  
          
        <FaEnvelope  color='purple ' size={25}/>

        </Link>

        
    
    <Link onClick={handleNav} to="/findjob">
    <button className='btnNav navt'>Post your job</button>
    
    </Link>
    <Link    onClick={handleNav}   to="/applyjobs">
    <button className='btnNav navt'>Apply to Jobs</button>
    
    </Link>
 
         
      <li      onClick={logOut} className="navt">Log out</li>
      
      
      <Link    onClick={handleNav}  className='bb' to="/account">
      <li className='navt'>{user?.email.slice(0, user?.email.lastIndexOf("@"))}</li>
      </Link>
        </>
   

  ) : (
    <>
    <Link    onClick={handleNav}  className='bb' to="/signin">
<li className='navt'>Sign In</li>
</Link>

<Link  onClick={handleNav}    className='bb' to="/signup">
<li  className='navt'>Sign Up</li>
</Link>
    </>


  )}
      
      
  </ul>



    </div>
  
</nav>




      



     

</div>
}

export default Navbar